import React from "react";

export default function Footer() {
  return (
    <>
      <div id="foot">
        <footer 
          id="footer-IE"
	  style={{
	    backgroundColor: "#005a5b"
	  }}
          className="sm:h-24 h-20 w-full bg-fixed fixed bottom-0"
        >
        </footer>
      </div>
    </>
  );
};
