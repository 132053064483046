import "../assets/css/ktu.css";
import { useState } from 'react';
import React from "react";
import Logo from "../assets/images/logo_2014.png";

export default function Header({ language, setLanguage }) {
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <header
        id="header-IE"
	style={{
	  backgroundColor: "#005a5b"
	}}
        className="sm:h-24 h-20"
      >
        <div
          className="wrap flex"
          id="header-wrap"
        >
          <div
            className="logospace"
          >
            <div
              id="logo"
              className="my-auto mt-2 sm:mt-2"
            >
              <img
                className="h-16 sm:h-20 py-0"
                src={Logo}
                alt="SimpleSAMLphp"
              />
            </div>
          </div>
          <div
            className="grow"
          />
          <div
              className="menuspace "
            >
              <div
                className="v-center h-20 sm:h-24 flex-shrink"
              >
                <select
                  onMouseEnter={handleMouseEnter}
	  	  onMouseLeave={handleMouseLeave}
	  	  style={{
		    backgroundColor: !isHover ? "#005a5b" : "#0f766e",
		    color: "white"
		  }}
	  	  className="language-button rounded-md py-2 px-2 flex items-center min-w-28 sm:min-w-32 gap-x-1 group text-white font-sans font-normal shrink"
                  onChange={handleLanguageChange}
                >
                  <option value='lt'>Lietuvių</option>
                  <option value='en'>English</option>
                </select>
              </div>
            </div>
        </div>
      </header>
    </>
  );
};
