import { useState, useEffect } from 'react';
import Header from './components/header';
import Body from './components/body';
import Footer from './components/footer';

//data will be the string we send from our server
// const apiCall = () => {
//   axios.get('http://localhost:8080').then((data) => {
//     //this console.log will be in our frontend console
//     console.log(data);
//   });
// };

function App() {
  const [language, setLanguage] = useState('lt');

  useEffect(() => {
    document.title = "2fa.login.ktu.lt"
  }, []);

  return (
      <div
        id="layout"
        className="flex w-full flex-col"
      >
        <Header language={language} setLanguage={setLanguage} />
        <Body language={language} />
        <Footer />
      </div>
  );
}

export default App;
