import axios from 'axios';
import "../assets/css/ktu.css";
import { useEffect, useState } from 'react';
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default function Body({ language }) {
    const [resendButtonText, setResendButtonText] = useState(language === 'lt' ? 'Siųsti kodą' : 'Send code');
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
    const [confirmationCodeSend, setConfirmationCodeSend] = useState(false);
    const [confirmationCodeEntered, setConfirmationCodeEntered] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [codeInputValue, setCodeInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [secretSet, setSecretSet] = useState(false);
    const [init, setInit] = useState(false);

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
    };

    const handleMouseLeave = () => {
      setIsHover(false);
    };

    const [isHoverResend, setIsHoverResend] = useState(false);

    const handleMouseEnterResend = () => {
      setIsHoverResend(true);
    };

    const handleMouseLeaveResend = () => {
      setIsHoverResend(false);
    };

    useEffect(() => {
        async function fetchData() {
            await axios
            .post('/api/checkIfSecretIsSet')
            .then((res) => {
                setSecretSet(res.data.secretSet);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        if(!init){
            fetchData();
            setInit(true);
        }
    }, [init]);

    const resendButtonMainActions = (sec) => {
        setResendButtonDisabled(true);
        var countdown = setInterval(function() {
            setResendButtonText(sec + 's');
            if (sec === 0) {
                clearInterval(countdown);
                setResendButtonText(language === 'lt' ? 'Siųsti kodą' : 'Send code');
                setResendButtonDisabled(false);
                return;
            };
            sec--;
        }, 1000);
    };

    const resendCode = async () => {
        await axios
            .post('/api/resendVerificationCode', {})
            .then(() => {
                setLoading(false);
                setConfirmationCodeSend(true);
                alert(language === 'lt' ? 'Naujas kodas išsiųstas' : 'New code has been sent');
            })
            .catch((error) => {
                setLoading(false);
                setConfirmationCodeSend(false);
                if (error.response.data.error === 'Prašome naudoti tokį telefono numerio formatą: +...') {
		    alert(language === 'lt' ? 'Klaida: ' + error.response.data.error : 'Error: Please use the following phone number format: +...');
		}
		console.error(error);
            })
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleCodeChange = (event) => {
        setCodeInputValue(event.target.value);
    };

    const handleCodeSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await axios
            .post('/api/checkEnteredCode', {
                code: codeInputValue
            })
            .then((res) => {
                setLoading(false);
                setConfirmationCodeEntered(true);
                if (res.data.trigger) {
                    if (res.data.trigger === 'time') {
                        alert(language === 'lt' ? res.data.data : 'Code expired. Try again.');
                        setLoading(false);
                        setConfirmationCodeSend(false);
                        setConfirmationCodeEntered(false);
                    } else if (res.data.trigger === 'code') {
                        alert(language === 'lt' ? res.data.data : 'Incorrect code.');
                        resendCode();
                        setLoading(false);
                        setConfirmationCodeSend(true);
                        setConfirmationCodeEntered(false);
                    }
                } else {
                    insertAndExit();
                }
            })
            .catch((error) => {
                alert('Klaida: ' + error.response.data.error);
                console.error(error);
            });
    };

    const insertAndExit = async () => {
        await axios
            .get('/api/insertAndExit')
            .then((res) => {
		        window.location.replace('https://login.ktu.lt/simplesaml/saml2/idp/SingleLogoutService.php/?ReturnTo='+res.data.restartUrl);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // mainResendButtonAction(60);
        await axios
            .post('/api/sendVerificationCode', {
                phone: inputValue
            })
            .then(() => {
                setLoading(false);
                setConfirmationCodeSend(true);
            })
            .catch((error) => {
                setLoading(false);
                setConfirmationCodeSend(false);
                if(error.response.data.error === "Prašome naudoti tokį telefono numerio formatą: +...") {
		    alert(language === 'lt' ? 'Klaida: ' + error.response.data.error : 'Error: Please use the following phone number format: +...');
		}
                console.error(error);
            })
        resendButtonMainActions(60);
    };

    return (
        <>
            {(loading || confirmationCodeEntered) && (
                <div
                    className="wrap"
                    style={{
                        padding: "10vh",
                        width: "80%",
                        maxWidth: "1100px",
                    }}
                >
                    <div
                        className="center-form login-form-start"
                        style={{
                            font: "inherit",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress variant="indeterminate" style={{ color: '#005a5b' }} />
                    </div>
                </div>
            )}
            {(!loading && !secretSet) && (
                <div
                className="wrap"
                style={{
                    padding: '4vh',
                    float: 'right',
                    alignItems: 'center',
                    width: "80%",
                }}
            >
                <h1
                    style={{
                        font: "inherit",
                        verticalAlign: "baseline",
                        fontSize: "2em",
                        fontWeight: 900,
                    }}
                >
                    {language === 'lt' ? 'Netinkamas nukreipimas, bandykite dar kartą.' : 'Wrong redirect, try again.'}
                </h1>
            </div>
            )}
            {!confirmationCodeSend && !loading && secretSet && (
                <main className="sm:pb-24 pb-20">
                    <div
                        id="content1"
                        className="pt-2 sm:pt-6"
                    >
                        <div
                            className="wrap"
                        >
                            <div
                                id="portalmenu"
                                className="ui-tabs ui-widget ui-widget-content ui-corner-all"
                            >
                                <div
                                    id="portalcontent"
                                    className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                                >
                                    <div
                                        className="text-2xl font-medium font-sans border-b border-neutral-300 border-solid tracking-tight"
                                    >
                                        {language === 'lt' ? 'Įveskite telefono numerį, kurį naudosite 2FA autentifikacijai' : 'Enter phone number which will be used for 2FA'}
                                    </div>
                                    <div
                                        className="center-form"
                                    >
                                        <form 
                                            onSubmit={handleSubmit}
                                        >
                                            <div
                                                className="pure-control-group"
                                            >
                                                <div
                                                    className="pure-control-group"
                                                >
                                                    <div
                                                        className="pure-controls login-form-submit"
                                                        style={{
                                                            marginRight: "0px",
                                                            marginLeft: "auto"
                                                        }}
                                                    >
                                                        <div
                                                            className="pure-controls-group jp-right"
                                                        >
                                                            <input
                                                                className="float-right focus:caret-teal-600 focus:outline-teal-600 focus:outline-2 outline-1 outline-neutral-300 outline px-2 py-1.5 rounded-sm shadow-sm focus:shadow-teal-700 w-64 sm:w-52"
                                                                id="phone"
                                                                name="phone"
                                                                type="text"
                                                                autoComplete="phone"
                                                                autoFocus
                                                                tabIndex="1"
                                                                defaultValue="+370"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <br/>
                                                        <div
                                                            className="mr-8 ml-8 sm:mr-0 sm:ml-auto float-none sm:float-end justify-end"
                                                        >
                                                            <div
                                                                className="flex flex-col sm:flex-row-reverse mr-0 ml-auto gap-2 m-4 mt-6 sm:mt-8 justify-end w-64 sm:w-auto"
                                                            >
                                                                <button
		    						    onMouseEnter={handleMouseEnter}
		    						    onMouseLeave={handleMouseLeave}
		    						    style={{
								      backgroundColor: !isHover ? "#005a5b" : "#0f766e",
								      color: "white"
								    }}
                                                                    className="focus:bg-teal-600 text-white rounded-md py-1.5 border-none shadow-md px-6 font-normal font-sans outline-none"
                                                                    type="submit"
                                                                >
                                                                    {language === 'lt' ? 'Išsaugoti' : 'Save'}
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
            {(confirmationCodeSend && !loading && !confirmationCodeEntered && secretSet) && (
                <main className="sm:pb-24 pb-20">
                    <div
                        id="content1"
                        className="pt-2 sm:pt-6"
                    >
                        <div
                            className="wrap"
                        >
                            <div
                                id="portalmenu"
                                className="ui-tabs ui-widget ui-widget-content ui-corner-all"
                            >
                                <div
                                    id="portalcontent"
                                    className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                                >
                                    <div
                                        className="text-2xl font-medium font-sans border-b border-neutral-300 border-solid tracking-tight"
                                    >
                                        {language === 'lt' ? 'Įveskite kodą, kurį gavote SMS žinute' : 'Enter code received via SMS'}
                                    </div>
                                    <div
                                        className="center-form"
                                    >
                                        <form 
                                            onSubmit={handleCodeSubmit}
                                        >
                                            <div
                                                className="pure-control-group"
                                            >
                                                <div
                                                    className="pure-control-group"
                                                >
                                                    <div
                                                        className="pure-controls login-form-submit"
                                                        style={{
                                                            marginRight: "0px",
                                                            marginLeft: "auto"
                                                        }}
                                                    >
                                                        <div
                                                            className="pure-controls-group jp-right"
                                                        >
                                                            <input
                                                                className="float-right focus:caret-teal-600 focus:outline-teal-600 focus:outline-2 outline-1 outline-neutral-300 outline px-2 py-1.5 rounded-sm shadow-sm focus:shadow-teal-700 w-64 sm:w-52"
                                                                id="code"
                                                                name="code"
                                                                type="text"
                                                                autoComplete="code"
                                                                autoFocus
                                                                tabIndex="1"
                                                                onChange={handleCodeChange}
                                                            />
                                                        </div>
                                                        <br/>
                                                        <div
                                                            className="flex flex-col sm:flex-row-reverse mr-0 ml-auto gap-2 m-4 mt-6 sm:mt-8 justify-end w-64 sm:w-auto"
                                                        >
                                                            <button
		    						onMouseEnter={handleMouseEnter}
		    						onMouseLeave={handleMouseLeave}
		    						style={{
								  backgroundColor: !isHover ? "#005a5b" : "#0f766e",
								  color: "white"
								}}
                                                                className="focus:bg-teal-600 text-white rounded-md py-1.5 border-none shadow-md px-6 font-normal font-sans outline-none"
                                                                type="submit"
                                                            >
                                                                {language === 'lt' ? 'Išsaugoti' : 'Save'}
                                                            </button>

                                                            <button
		    						onMouseEnter={handleMouseEnterResend}
		    						onMouseLeave={handleMouseLeaveResend}
		    						style={{
									backgroundColor: !isHoverResend ? "#ffffff" : "#005a5b",
								  color: !isHoverResend ? "#005a5b" : "#ffffff"
								}}
                                                                className="flex focus:bg-teal-600 text-teal-800 hover:text-white focus:text-white rounded-md py-1.5 border-none px-6 font-sans outline-none px-6 font-sans outline-none font-semibold justify-center disabled:hover:bg-neutral-100 disabled:hover:text-neutral-400 disabled:text-neutral-400 disabled:cursor-not-allowed disabled:bg-neutral-100"
                                                                disabled={ resendButtonDisabled }
                                                                onClick={() =>{
                                                                    resendCode();
                                                                    resendButtonMainActions(60);
                                                                }}
                                                            >
                                                                <svg 
                                                                    className="mr-1 mt-1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    // class="bi bi-chat-left-text"
                                                                    viewbox="0 0 16 16"
                                                                >
                                                                    <path 
                                                                        d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                                                    />
                                                                    <path 
                                                                        d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"
                                                                    />
                                                                </svg>
                                                                { resendButtonText }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div>
                                        <h4
                                            style={{
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {language === 'lt' ? 'Patvirtinus būsite nukreipti į paslaugą pakartoti prisijungimą.' : 'You will be redirected back to the service after the confirmation. You will need to sign in again.'}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
}
