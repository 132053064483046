// src/index.js
import 'react-app-polyfill/ie11';  // React polyfill for IE11
import 'core-js/stable';  // Core-js polyfills
import 'regenerator-runtime/runtime';  // For async/await transpilation

import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/ktu.css';
import './assets/css/stylesheet.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
reportWebVitals();
